import debounce from 'debounce-promise';
import http from '../plugins/http';

const list = (page, per_page, name) => http.get('/technician', { params: { page, per_page, name } });

export default {
  detail: id => http.get(`/technician/${id}`),
  create: payload => http.post('/technician', payload),
  update: (id, payload) => http.put(`/technician/${id}`, payload),
  list,
  listDebounce: debounce(list, 400)
};
