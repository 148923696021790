<template>
  <div class="vx-col w-full">
    <div class="centerx">
      <vs-popup class="holamundo" :title="`Update ticket`" :active.sync="modal.active">
        <div class="vx-row" data-vv-scope="item-modal" v-if="mounted">
          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">Status</label>
            <multiselect
              :class="{ 'is-danger': errors.has('status') }"
              v-validate="'required'"
              name="status"
              v-model="modal.form.status"
              :options="fillables.status"
              :custom-label="({ name }) => name"
              label="name"
              track-by="name"
              :max-height="175"
              placeholder="Select status"
            />
            <div
              v-if="errors.has('status')"
              class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
              style="height: 19px;"
            >
              <span class="span-text-validation">
                {{ errors.first('status') }}
              </span>
            </div>
          </div>
          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">Technician</label>
            <multiselect
              :class="{ 'is-danger': errors.has('technician') }"
              v-validate="'required'"
              name="technician"
              v-model="modal.form.technician"
              :options="fillables.technician"
              :custom-label="({ name }) => name"
              label="name"
              track-by="name"
              :max-height="175"
              placeholder="Select technician"
            />
          </div>
        </div>
        <vs-divider />
        <div class="vx-col w-full mt-5">
          <vs-button :disabled="this.errors.items.length || !this.modal.form.technician" @click="updateTicket" color="success" type="filled" class="w-full">
            Save changes
          </vs-button>
        </div>
      </vs-popup>
      <vs-popup class="holamundo" :title="qr.title" :active.sync="qr.active">
        <div class="vx-row" data-vv-scope="item-modal" v-if="mounted">
          <div class="vx-col w-1/3 mx-auto self-center">
            <canvas ref="canvas-qr" />
          </div>
        </div>
      </vs-popup>
    </div>
    <vs-input icon-after="true" icon-pack="feather" icon="icon-search" label-placeholder="Search" v-model="search.name" class="w-full no-icon-border is-label-placeholder" />
    <div class="vx-row w-1/2 m-5">
      <vs-checkbox v-model="search.status" :vs-value="10" color="primary">
        Queue
      </vs-checkbox>
      <vs-checkbox v-model="search.status" :vs-value="20" color="warning">
        Ongoing
      </vs-checkbox>
      <vs-checkbox v-model="search.status" :vs-value="30" color="success">
        Finished
      </vs-checkbox>
      <vs-checkbox v-model="search.status" :vs-value="35" color="rgb(128,128,128)">
        Out
      </vs-checkbox>
    </div>
    <vx-card>
      <div slot="no-body" class="mt-3">
        <div id="div-with-loading" class="vs-con-loading__container">
          <vs-table :data="tickets" class="table-dark-inverted" @selected="selectTicket">
            <template slot="thead">
              <vs-th>Item</vs-th>
              <vs-th>Treatment</vs-th>
              <vs-th>Status</vs-th>
              <vs-th>Time</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="tr.id" v-for="(tr) in data" :data="tr">
                <vs-td :data="tr.item" class="centerx">
                  <b>{{ tr.item }}</b> &nbsp;
                  <span class="item-desc">{{ tr.item_detail }}</span>
                </vs-td>
                <vs-td :data="tr.treatment" class="text-primary">
                  <b>{{ tr.treatment }}</b>
                </vs-td>
                <vs-td :data="tr.status">
                  <vs-chip :color="status_color[tr.status]">
                    {{ status_name[tr.status] }}
                  </vs-chip>
                </vs-td>
                <vs-td :data="tr.created_at">
                  <vs-chip>
                    {{ fromNow(tr.created_at) }}
                  </vs-chip>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </vx-card>
    <div>
      <br>
      <vs-pagination :total="meta.total_page" v-model="meta.page" :max="7" />
    </div>
    <vs-popup class="holamundo" :title="selected_ticket.item" :active.sync="popupActivo">
      <vs-list>
        <vs-list-item title="Detail" :subtitle="selected_ticket.item_detail" />
        <vs-list-item title="Receipt" :subtitle="selected_ticket.invoice_no" />
        <vs-list-item title="Technichian" :subtitle="selected_ticket.technician ? selected_ticket.technician.name : '-'" />
        <vs-list-item title="Notes" :subtitle="selected_ticket.notes || '-'" />
      </vs-list>
      <div class="vx-row w-full mt-5 ml-5">
        <div class="vx-col w-1/3">
          <vs-button color="warning" @click="() => loadEdit(selected_ticket)">
            Update
          </vs-button>
        </div>
        <div class="vx-col w-1/3">
          <vs-button color="gray" @click="() => navigate(selected_ticket.invoice_no)">
            Invoice
          </vs-button>
        </div>
        <div class="vx-col w-1/3">
          <vs-button color="primary" @click="() => showQR(selected_ticket.id)">
            Show QR
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import moment from 'moment';
import qrcode from 'qrcode';
import Pagination from '@/utils/mixins/pagination';
import InvoiceRepo from '@/repositories/invoice_repo';
import TechnicianRepo from '@/repositories/technician_repo';
import { INVOICE_STATUS_NAME, STATUS_COLOR } from '@/utils/constants';

export default {
  mixins: [Pagination],
  data() {
    return {
      selected_ticket: {
        id: null,
        item: '',
        item_detail: '',
        status: null,
        treatment: '',
        technician: {},
        invoice_no: '',
        notes: '',
        created_at: ''
      },
      popupActivo: false,
      mounted: false,
      status_name: INVOICE_STATUS_NAME,
      status_color: STATUS_COLOR,
      tickets: [],
      qr: {
        active: false,
        title: ''
      },
      fillables: {
        status: [
          // { name: 'Queue', value: 10 },
          { name: 'Ongoing', value: 20 },
          { name: 'Finish', value: 30 },
          { name: 'Out', value: 35 }
        ],
        technician: []
      },
      search: {
        status: [10, 20],
        sort: 'created_at',
        per_page: 50
      },
      modal: {
        active: false,
        action: 'update',
        form: {
          id: null,
          status: null,
          technician: null
        }
      }
    };
  },
  methods: {
    loadData({
      search = this.search.name, sort = this.search.sort, status = this.search.status, page, per_page = this.search.per_page
    }) {
      this.loadingTrue();
      return InvoiceRepo.listTicketDebounce({
        invNo: search, sort, status: status.join(','), page, per_page
      })
        .then(({ data: { data: tickets, pagination: meta } }) => {
          this.tickets = tickets.map((item) => {
            const p = item.invoice_item.snapshot;
            const itemDetail = `(${[p.material, p.color, p.size, p.lace, p.insole].filter(i => i && i !== '-').join(' / ')})`;
            return {
              id: item.id,
              item: `${p.brand} ${p.model}`,
              item_detail: itemDetail,
              status: item.status,
              treatment: item.snapshot.name,
              technician: item.technician,
              invoice_no: item.invoice.invoice_no,
              notes: item.notes,
              created_at: item.created_at
            };
          });
          this.meta = meta;
          this.loadingFalse();
        });
    },
    loadEdit(ticket) {
      this.modal.form = {
        id: ticket.id,
        status: { name: this.status_name[ticket.status], value: ticket.status },
        technician: ticket.technician ? { name: ticket.technician.name, value: ticket.technician.id } : null
      };
      this.modal.action = 'update';
      this.modal.active = true;
    },
    async loadTechnician() {
      return TechnicianRepo.list(1, 999)
        .then(({ data: { data: technicians } }) => {
          this.fillables.technician = technicians.map(tech => ({ name: tech.name, value: tech.id }));
        });
    },
    async updateTicket() {
      const payload = {
        technician_id: this.modal.form.technician && this.modal.form.technician.value || null,
        status: this.modal.form.status.value
      };
      return InvoiceRepo.updateTicket(this.modal.form.id, payload)
        .then(() => {
          this.modal.active = false;
          this.popupActivo = false;
          return this.loadData({ page: this.meta.page, per_page: this.meta.per_page });
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Whoops',
            text: err.message,
            color: 'danger',
            position: 'bottom-center'
          });
        });
    },
    fromNow(date) {
      return moment(date).fromNow();
    },
    navigate(invoice_no) {
      this.$router.push(`/invoices/${invoice_no}`);
    },
    async showQR(id) {
      await qrcode.toCanvas(this.$refs['canvas-qr'], id)
        .then(() => {
          const ticket = this.tickets.find(ticket => ticket.id === id);
          this.qr.title = `${ticket.item} ${ticket.item_detail}`;
          this.qr.active = true;
        });
    },
    selectTicket(ticket) {
      this.selected_ticket = ticket;
      this.popupActivo = true;
    }
  },
  async beforeMount() {
    await this.loadData({});
    await this.loadTechnician();
    this.mounted = true;
  },
  watch: {
    'search.status': async function() {
      await this.loadData({});
    }
  }
};
</script>

<style scoped>
.item-desc {
  font-size: 12px;
}
</style>
